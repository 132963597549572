import React, { useEffect } from "react"

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

export function IosVersion({ iosVersion, setIosVersion }) {
  // useEffect(() => {
  //   console.log("iosVersion", iosVersion)
  // }, [iosVersion])

  const radios = [
    { value: "g2", text: "VIRL CSR (g2)" },
    { value: "g0/0", text: "g0/0" },
    { value: "g0/1", text: "g0/1" },
    { value: "g0", text: "g0" },
    { value: "g1", text: "g1" }
  ]
  return (
    <ButtonGroup toggle vertical>
      {radios.map((radio, idx) => (
        <ToggleButton
          key={idx}
          type="radio"
          variant="secondary"
          name="radio"
          value={radio.value}
          checked={iosVersion === radio.value}
          onChange={(e) => setIosVersion(e.currentTarget.value)}
        >
          {radio.text}
        </ToggleButton>
      ))}
    </ButtonGroup>
  )
}
