import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
*,
*::after,
*::before {
box-sizing: border-box;
}

body {
background: ${({ theme }) => theme.body};
color: ${({ theme }) => theme.text};
}

h1 {
color: ${({ theme }) => theme.title};
}

a.ninja {
    color: ${({ theme }) => theme.title};
}

.link:hover {
background-color: ${({ theme }) => theme.hoverbody};
}`