export const builtInConfigs = [
    {
        id: "9883d11fbd3fae9974a9bacdc36fc89300406f793ecee35e03b99ba0f7a744f4",
        name: "sample.tutorial"
    },
    {
        id: "313c906fccc3056836036695e365881b7528f13a839821eed837d78fbdf8223d",
        name: "ine.pardasani.bgp.addressing"
    },
    {
        id: "6834a2f9f38f41778aef2b233f2b367f8944e82fedb5309a9a37e43833a2bb2d",
        name: "ine.pardasani.bgp.basic"
    }

]
const builtInConfigs2 = [
    {
        id: "sample.tutorial",
        type: "sample",
        name: "sample.tutorial",
        config: `
USAGE

Separate layers with "!!! title", separate devices within a layer with "! devicename". Anything before the first !!! is a README. Press "Show" for a full example.

The "reference" section is for convenience; you can copy/paste to speed up configuration.

Click a device to copy the config for that device for that layer. Paste into your SSH tool. Press n or click "next" to jump to the next device.

This is not meant to be an editor. That would make this tool do too much. A tool should do one thing and do it well. This parses layers -- VSCode and Notepad++ edit.

TIP

Use the Logical Topology Generator to create a topology. Export to this tool.

Link at top.
!!! topology

r1:r2

!!! addressing

!! r1
conf t
hostname r1

no ip domain-lookup
line console 0
 logging sync
 exec-timeout 0 0
line vty 0 4
 logging sync
 exec-timeout 0 0

interface lo0
 ip address 1.1.1.1 255.255.255.255
 ipv6 address 2001:db8::1/128

interface g2.12
 encapsulation dot1q 12
 ip address 10.1.2.1 255.255.255.0
 ipv6 address 2001:db8:1:2::1/64




interface g2
 no shut

end




!! r2
conf t
hostname r2

no ip domain-lookup
line console 0
 logging sync
 exec-timeout 0 0
line vty 0 4
 logging sync
 exec-timeout 0 0

interface lo0
 ip address 2.2.2.2 255.255.255.255
 ipv6 address 2001:db8::2/128

interface g2.12
 encapsulation dot1q 12
 ip address 10.1.2.2 255.255.255.0
 ipv6 address 2001:db8:1:2::2/64




interface g2
 no shut

end


!!! reference

!! r1
conf t
interface g2.12
end


!! r2
conf t
interface g2.12
end
`
    },
    {
        id: 'sample.mpls.ospf',
        type: "sample",
        name: "mpls.ospf",
        config: `OSPF underlay with MPLS. CEs on two separate VRFs connect via EIGRP.
!!! topology

r1:r2,r3,r4,r5,r6
r2:r3
r3:r4
r4:r5,r9
r5:r6
r6:r2
r7:r2,r3,r6,r8

!!! addressing

!! r1
conf t
hostname r1

no ip domain-lookup
line console 0
 logging sync
 exec-timeout 0 0
line vty 0 4
 logging sync
 exec-timeout 0 0

interface lo0
 ip address 1.1.1.1 255.255.255.255
 ipv6 address 2001:db8::1/128

interface g0/0.1
 encapsulation dot1q 1
 ip address 172.16.10.1 255.255.255.0
 ipv6 address 2001:db8:10::1/64

interface g0/0.12
 encapsulation dot1q 12
 ip address 10.1.2.1 255.255.255.0
 ipv6 address 2001:db8:1:2::1/64


interface g0/0.13
 encapsulation dot1q 13
 ip address 10.1.3.1 255.255.255.0
 ipv6 address 2001:db8:1:3::1/64


interface g0/0.14
 encapsulation dot1q 14
 ip address 10.1.4.1 255.255.255.0
 ipv6 address 2001:db8:1:4::1/64


interface g0/0.15
 encapsulation dot1q 15
 ip address 10.1.5.1 255.255.255.0
 ipv6 address 2001:db8:1:5::1/64


interface g0/0.16
 encapsulation dot1q 16
 ip address 10.1.6.1 255.255.255.0
 ipv6 address 2001:db8:1:6::1/64




interface g0/0
 no shut

end




!! r2
conf t
hostname r2

no ip domain-lookup
line console 0
 logging sync
 exec-timeout 0 0
line vty 0 4
 logging sync
 exec-timeout 0 0

interface lo0
 ip address 2.2.2.2 255.255.255.255
 ipv6 address 2001:db8::2/128

interface g0/0.2
 encapsulation dot1q 2
 ip address 172.16.20.2 255.255.255.0
 ipv6 address 2001:db8:20::2/64

interface g0/0.12
 encapsulation dot1q 12
 ip address 10.1.2.2 255.255.255.0
 ipv6 address 2001:db8:1:2::2/64


interface g0/0.23
 encapsulation dot1q 23
 ip address 10.2.3.2 255.255.255.0
 ipv6 address 2001:db8:2:3::2/64


interface g0/0.26
 encapsulation dot1q 26
 ip address 10.2.6.2 255.255.255.0
 ipv6 address 2001:db8:2:6::2/64


interface g0/0.27
 encapsulation dot1q 27
 ip address 10.2.7.2 255.255.255.0
 ipv6 address 2001:db8:2:7::2/64




interface g0/0
 no shut

end




!! r3
conf t
hostname r3

no ip domain-lookup
line console 0
 logging sync
 exec-timeout 0 0
line vty 0 4
 logging sync
 exec-timeout 0 0

interface lo0
 ip address 3.3.3.3 255.255.255.255
 ipv6 address 2001:db8::3/128

interface g0/0.3
 encapsulation dot1q 3
 ip address 172.16.30.3 255.255.255.0
 ipv6 address 2001:db8:30::3/64

interface g0/0.13
 encapsulation dot1q 13
 ip address 10.1.3.3 255.255.255.0
 ipv6 address 2001:db8:1:3::3/64


interface g0/0.23
 encapsulation dot1q 23
 ip address 10.2.3.3 255.255.255.0
 ipv6 address 2001:db8:2:3::3/64


interface g0/0.34
 encapsulation dot1q 34
 ip address 10.3.4.3 255.255.255.0
 ipv6 address 2001:db8:3:4::3/64


interface g0/0.37
 encapsulation dot1q 37
 ip address 10.3.7.3 255.255.255.0
 ipv6 address 2001:db8:3:7::3/64




interface g0/0
 no shut

end




!! r4
conf t
hostname r4

no ip domain-lookup
line console 0
 logging sync
 exec-timeout 0 0
line vty 0 4
 logging sync
 exec-timeout 0 0

interface lo0
 ip address 4.4.4.4 255.255.255.255
 ipv6 address 2001:db8::4/128

interface g0/0.4
 encapsulation dot1q 4
 ip address 172.16.40.4 255.255.255.0
 ipv6 address 2001:db8:40::4/64

interface g0/0.14
 encapsulation dot1q 14
 ip address 10.1.4.4 255.255.255.0
 ipv6 address 2001:db8:1:4::4/64


interface g0/0.34
 encapsulation dot1q 34
 ip address 10.3.4.4 255.255.255.0
 ipv6 address 2001:db8:3:4::4/64


interface g0/0.45
 encapsulation dot1q 45
 ip address 10.4.5.4 255.255.255.0
 ipv6 address 2001:db8:4:5::4/64


interface g0/0.49
 encapsulation dot1q 49
 ip address 10.4.9.4 255.255.255.0
 ipv6 address 2001:db8:4:9::4/64




interface g0/0
 no shut

end




!! r5
conf t
hostname r5

no ip domain-lookup
line console 0
 logging sync
 exec-timeout 0 0
line vty 0 4
 logging sync
 exec-timeout 0 0

interface lo0
 ip address 5.5.5.5 255.255.255.255
 ipv6 address 2001:db8::5/128

interface g0/0.5
 encapsulation dot1q 5
 ip address 172.16.50.5 255.255.255.0
 ipv6 address 2001:db8:50::5/64

interface g0/0.15
 encapsulation dot1q 15
 ip address 10.1.5.5 255.255.255.0
 ipv6 address 2001:db8:1:5::5/64


interface g0/0.45
 encapsulation dot1q 45
 ip address 10.4.5.5 255.255.255.0
 ipv6 address 2001:db8:4:5::5/64


interface g0/0.56
 encapsulation dot1q 56
 ip address 10.5.6.5 255.255.255.0
 ipv6 address 2001:db8:5:6::5/64




interface g0/0
 no shut

end




!! r6
conf t
hostname r6

no ip domain-lookup
line console 0
 logging sync
 exec-timeout 0 0
line vty 0 4
 logging sync
 exec-timeout 0 0

interface lo0
 ip address 6.6.6.6 255.255.255.255
 ipv6 address 2001:db8::6/128

interface g0/0.6
 encapsulation dot1q 6
 ip address 172.16.60.6 255.255.255.0
 ipv6 address 2001:db8:60::6/64

interface g0/0.16
 encapsulation dot1q 16
 ip address 10.1.6.6 255.255.255.0
 ipv6 address 2001:db8:1:6::6/64


interface g0/0.26
 encapsulation dot1q 26
 ip address 10.2.6.6 255.255.255.0
 ipv6 address 2001:db8:2:6::6/64


interface g0/0.56
 encapsulation dot1q 56
 ip address 10.5.6.6 255.255.255.0
 ipv6 address 2001:db8:5:6::6/64


interface g0/0.67
 encapsulation dot1q 67
 ip address 10.6.7.6 255.255.255.0
 ipv6 address 2001:db8:6:7::6/64




interface g0/0
 no shut

end




!! r7
conf t
hostname r7

no ip domain-lookup
line console 0
 logging sync
 exec-timeout 0 0
line vty 0 4
 logging sync
 exec-timeout 0 0

interface lo0
 ip address 7.7.7.7 255.255.255.255
 ipv6 address 2001:db8::7/128

interface g0/0.7
 encapsulation dot1q 7
 ip address 172.16.70.7 255.255.255.0
 ipv6 address 2001:db8:70::7/64

interface g0/0.27
 encapsulation dot1q 27
 ip address 10.2.7.7 255.255.255.0
 ipv6 address 2001:db8:2:7::7/64


interface g0/0.37
 encapsulation dot1q 37
 ip address 10.3.7.7 255.255.255.0
 ipv6 address 2001:db8:3:7::7/64


interface g0/0.67
 encapsulation dot1q 67
 ip address 10.6.7.7 255.255.255.0
 ipv6 address 2001:db8:6:7::7/64


interface g0/0.78
 encapsulation dot1q 78
 ip address 10.7.8.7 255.255.255.0
 ipv6 address 2001:db8:7:8::7/64




interface g0/0
 no shut

end




!! r8
conf t
hostname r8

no ip domain-lookup
line console 0
 logging sync
 exec-timeout 0 0
line vty 0 4
 logging sync
 exec-timeout 0 0

interface lo0
 ip address 8.8.8.8 255.255.255.255
 ipv6 address 2001:db8::8/128

interface g0/0.8
 encapsulation dot1q 8
 ip address 172.16.80.8 255.255.255.0
 ipv6 address 2001:db8:80::8/64

interface g0/0.78
 encapsulation dot1q 78
 ip address 10.7.8.8 255.255.255.0
 ipv6 address 2001:db8:7:8::8/64




interface g0/0
 no shut

end




!! r9
conf t
hostname r9

no ip domain-lookup
line console 0
 logging sync
 exec-timeout 0 0
line vty 0 4
 logging sync
 exec-timeout 0 0

interface lo0
 ip address 9.9.9.9 255.255.255.255
 ipv6 address 2001:db8::9/128

interface g0/0.9
 encapsulation dot1q 9
 ip address 172.16.90.9 255.255.255.0
 ipv6 address 2001:db8:90::9/64

interface g0/0.49
 encapsulation dot1q 49
 ip address 10.4.9.9 255.255.255.0
 ipv6 address 2001:db8:4:9::9/64




interface g0/0
 no shut

end


!!! ospf

!! r1
conf t
interface lo0
    ip ospf 1 area 0
interface g0/0.12
    ip ospf network point-to-point
    ip ospf 1 area 0
interface g0/0.13
    ip ospf network point-to-point
    ip ospf 1 area 0
interface g0/0.14
    ip ospf network point-to-point
    ip ospf 1 area 0
interface g0/0.15
    ip ospf network point-to-point
    ip ospf 1 area 0
interface g0/0.16
    ip ospf network point-to-point
    ip ospf 1 area 0
end


!! r2
conf t
interface lo0
    ip ospf 1 area 0
interface g0/0.12
    ip ospf network point-to-point
    ip ospf 1 area 0
interface g0/0.23
    ip ospf network point-to-point
    ip ospf 1 area 0
interface g0/0.26
    ip ospf network point-to-point
    ip ospf 1 area 0
interface g0/0.27
    ip ospf network point-to-point
    ip ospf 1 area 0
end


!! r3
conf t
interface lo0
    ip ospf 1 area 0
interface g0/0.13
    ip ospf network point-to-point
    ip ospf 1 area 0
interface g0/0.23
    ip ospf network point-to-point
    ip ospf 1 area 0
interface g0/0.34
    ip ospf network point-to-point
    ip ospf 1 area 0
interface g0/0.37
    ip ospf network point-to-point
    ip ospf 1 area 0
end


!! r4
conf t
interface lo0
    ip ospf 1 area 0
interface g0/0.14
    ip ospf network point-to-point
    ip ospf 1 area 0
interface g0/0.34
    ip ospf network point-to-point
    ip ospf 1 area 0
interface g0/0.45
    ip ospf network point-to-point
    ip ospf 1 area 0
end


!! r5
conf t
interface lo0
    ip ospf 1 area 0
interface g0/0.15
    ip ospf network point-to-point
    ip ospf 1 area 0
interface g0/0.45
    ip ospf network point-to-point
    ip ospf 1 area 0
interface g0/0.56
    ip ospf network point-to-point
    ip ospf 1 area 0
end


!! r6
conf t
interface lo0
    ip ospf 1 area 0
interface g0/0.16
    ip ospf network point-to-point
    ip ospf 1 area 0
interface g0/0.26
    ip ospf network point-to-point
    ip ospf 1 area 0
interface g0/0.56
    ip ospf network point-to-point
    ip ospf 1 area 0
interface g0/0.67
    ip ospf network point-to-point
    ip ospf 1 area 0
end


!! r7
conf t
interface lo0
    ip ospf 1 area 0
interface g0/0.27
    ip ospf network point-to-point
    ip ospf 1 area 0
interface g0/0.37
    ip ospf network point-to-point
    ip ospf 1 area 0
interface g0/0.67
    ip ospf network point-to-point
    ip ospf 1 area 0
end

!!! mpls

!! r1
conf t
mpls label range 1000 1099
router ospf 1
 mpls ldp autoconfig area 0
end


!! r2
conf t
mpls label range 2000 2099
router ospf 1
 mpls ldp autoconfig area 0
end


!! r3
conf t
mpls label range 3000 3099
router ospf 1
 mpls ldp autoconfig area 0
end


!! r4
conf t
mpls label range 4000 4099
router ospf 1
 mpls ldp autoconfig area 0
end


!! r5
conf t
mpls label range 5000 5099
router ospf 1
 mpls ldp autoconfig area 0
end


!! r6
conf t
mpls label range 6000 6099
router ospf 1
 mpls ldp autoconfig area 0
end


!! r7
conf t
mpls label range 7000 7099
router ospf 1
 mpls ldp autoconfig area 0
end

!!! mpls verification

!! r1,r2,r3,r4,r5,r6,r7
show mpls ldp neighbor
show mpls forwarding-table

!!! vrf

!! r4
conf t
vrf definition BLUE
 rd 400:1
 route-target export 400:1
 route-target import 700:1
 address-family ipv4
 address-family ipv6
interface lo1
    vrf forwarding BLUE
    ip address 44.44.44.44 255.255.255.0
    ipv6 address 2001:db8:44:44::4/64
interface g0/0.49
 vrf forwarding BLUE
 ip address 10.4.9.4 255.255.255.0
 ipv6 address 2001:db8:4:9::4/64
end

!! r7
conf t
vrf definition RED
 rd 700:1
 route-target export 700:1
 route-target import 400:1
 address-family ipv4
 address-family ipv6
interface lo1
    vrf forwarding RED
    ip address 77.77.77.77 255.255.255.0
    ipv6 address 2001:db8:77:77::4/64
interface g0/0.78
 vrf forwarding RED
 ip address 10.7.8.7 255.255.255.0
 ipv6 address 2001:db8:7:8::7/64
end

!!! pe

!! r4
conf t
router eigrp CLIENT_A
 address-family ipv4 unicast vrf BLUE autonomous-system 1
  network 10.4.9.4 0.0.0.0
  af-interface default
   passive-interface
  af-interface GigabitEthernet2.49
   no passive-interface
router bgp 65000
 neighbor 7.7.7.7 remote-as 65000
 neighbor 7.7.7.7 update-source lo0
 no bgp default ipv4-unicast
 address-family vpnv4
  neighbor 7.7.7.7 activate
  neighbor 7.7.7.7 send-community extended
end

!! r7
conf t
router eigrp CLIENT_B
 address-family ipv4 unicast vrf RED autonomous-system 1
  network 10.7.8.7 0.0.0.0
  af-interface default
   passive-interface
  af-interface GigabitEthernet2.78
   no passive-interface
router bgp 65000
 neighbor 4.4.4.4 remote-as 65000
 neighbor 4.4.4.4 update-source lo0
 no bgp default ipv4-unicast
 address-family vpnv4
  neighbor 4.4.4.4 activate
  neighbor 4.4.4.4 send-community extended
end

!!! redistribution

!! r4

conf t
ip prefix-list EIGRP_TO_BGP seq 5 permit 9.9.9.9/32
route-map EIGRP_TO_BGP permit 10 
 match ip address prefix-list EIGRP_TO_BGP
ip prefix-list BGP_TO_EIGRP seq 5 permit 8.8.8.8/32
route-map BGP_TO_EIGRP permit 10 
 match ip address prefix-list BGP_TO_EIGRP 
 set metric 1 1 1 1 1
router bgp 65000
 address-family vpnv4
  address-family ipv4 unicast vrf BLUE
    redistribute eigrp 1 route-map EIGRP_TO_BGP
router eigrp CLIENT_A
  address-family ipv4 unicast vrf BLUE autonomous-system 1
    topology base
      redistribute bgp 65000 route-map BGP_TO_EIGRP
end

!! r7
conf t
ip prefix-list EIGRP_TO_BGP seq 5 permit 8.8.8.8/32
route-map EIGRP_TO_BGP permit 10 
 match ip address prefix-list EIGRP_TO_BGP
ip prefix-list BGP_TO_EIGRP seq 5 permit 9.9.9.9/32
route-map BGP_TO_EIGRP permit 10 
 match ip address prefix-list BGP_TO_EIGRP 
 set metric 1 1 1 1 1
router bgp 65000
 address-family vpnv4
  address-family ipv4 unicast vrf RED
    redistribute eigrp 1 route-map EIGRP_TO_BGP
router eigrp CLIENT_B
  address-family ipv4 unicast vrf RED autonomous-system 1
    topology base
      redistribute bgp 65000 route-map BGP_TO_EIGRP
end

!!! bgp verification

!! r4,r7

show ip bgp vpnv4 all

!!! ce

!! r8
conf t
router eigrp 1
  network 10.7.8.8 0.0.0.0
  network 8.8.8.8 0.0.0.0
  passive-interface default
  no passive-interface g0/0.78
end

!! r9
conf t
router eigrp 1
  network 10.4.9.9 0.0.0.0
  network 9.9.9.9 0.0.0.0
  passive-interface default
  no passive-interface g0/0.49
end

!!! reference

!! r1
conf t
interface g0/0.12
interface g0/0.13
interface g0/0.14
interface g0/0.15
interface g0/0.16
end


!! r2
conf t
interface g0/0.12
interface g0/0.23
interface g0/0.26
interface g0/0.27
end


!! r3
conf t
interface g0/0.13
interface g0/0.23
interface g0/0.34
interface g0/0.37
end


!! r4
conf t
interface g0/0.14
interface g0/0.34
interface g0/0.45
interface g0/0.49
end


!! r5
conf t
interface g0/0.15
interface g0/0.45
interface g0/0.56
end


!! r6
conf t
interface g0/0.16
interface g0/0.26
interface g0/0.56
interface g0/0.67
end


!! r7
conf t
interface g0/0.27
interface g0/0.37
interface g0/0.67
interface g0/0.78
end


!! r8
conf t
interface g0/0.78
end


!! r9
conf t
interface g0/0.49
end`},
    {
        id: 'intpardasanibgp',
        type: 'sample',
        name: 'ine.pardasani.bgp',
        config: `
Created from watching an INE Pardasani BGP series in 2019.

!!! topology

r1:r2,r3
r4:r5
r2:r4
r5:r6
r3:r6
g1:r1,r2,r3
g2:r4
g3:r5
g4:r6

!!!addressing

!! r1
conf t
hostname r1

interface lo0
 ip address 1.1.1.1 255.255.255.255
 ipv6 address 2001:db8::1/128

interface g1.1
 encapsulation dot1q 1
 ip address 172.16.10.1 255.255.255.0
 ipv6 address 2001:db8:10::1/64

interface g1.12
 encapsulation dot1q 12
 ip address 10.1.2.1 255.255.255.0
 ipv6 address 2001:db8:1:2::1/64

interface g1.13
 encapsulation dot1q 13
 ip address 10.1.3.1 255.255.255.0
 ipv6 address 2001:db8:1:3::1/64

interface g1
 no shut

end




!! r2
conf t
hostname r2

interface lo0
 ip address 2.2.2.2 255.255.255.255
 ipv6 address 2001:db8::2/128

interface g1.2
 encapsulation dot1q 2
 ip address 172.16.20.2 255.255.255.0
 ipv6 address 2001:db8:20::2/64

interface g1.12
 encapsulation dot1q 12
 ip address 10.1.2.2 255.255.255.0
 ipv6 address 2001:db8:1:2::2/64

interface g1.24
 encapsulation dot1q 24
 ip address 10.2.4.2 255.255.255.0
 ipv6 address 2001:db8:2:4::2/64

interface g1
 no shut

end




!! r3
conf t
hostname r3

interface lo0
 ip address 3.3.3.3 255.255.255.255
 ipv6 address 2001:db8::3/128

interface g1.3
 encapsulation dot1q 3
 ip address 172.16.30.3 255.255.255.0
 ipv6 address 2001:db8:30::3/64

interface g1.13
 encapsulation dot1q 13
 ip address 10.1.3.3 255.255.255.0
 ipv6 address 2001:db8:1:3::3/64

interface g1.36
 encapsulation dot1q 36
 ip address 10.3.6.3 255.255.255.0
 ipv6 address 2001:db8:3:6::3/64

interface g1
 no shut

end




!! r4
conf t
hostname r4

interface lo0
 ip address 4.4.4.4 255.255.255.255
 ipv6 address 2001:db8::4/128

interface g1.4
 encapsulation dot1q 4
 ip address 172.16.40.4 255.255.255.0
 ipv6 address 2001:db8:40::4/64

interface g1.24
 encapsulation dot1q 24
 ip address 10.2.4.4 255.255.255.0
 ipv6 address 2001:db8:2:4::4/64

interface g1.45
 encapsulation dot1q 45
 ip address 10.4.5.4 255.255.255.0
 ipv6 address 2001:db8:4:5::4/64

interface g1
 no shut

end




!! r5
conf t
hostname r5

interface lo0
 ip address 5.5.5.5 255.255.255.255
 ipv6 address 2001:db8::5/128

interface g1.5
 encapsulation dot1q 5
 ip address 172.16.50.5 255.255.255.0
 ipv6 address 2001:db8:50::5/64

interface g1.45
 encapsulation dot1q 45
 ip address 10.4.5.5 255.255.255.0
 ipv6 address 2001:db8:4:5::5/64

interface g1.56
 encapsulation dot1q 56
 ip address 10.5.6.5 255.255.255.0
 ipv6 address 2001:db8:5:6::5/64

interface g1
 no shut

end




!! r6
conf t
hostname r6

interface lo0
 ip address 6.6.6.6 255.255.255.255
 ipv6 address 2001:db8::6/128

interface g1.6
 encapsulation dot1q 6
 ip address 172.16.60.6 255.255.255.0
 ipv6 address 2001:db8:60::6/64

interface g1.36
 encapsulation dot1q 36
 ip address 10.3.6.6 255.255.255.0
 ipv6 address 2001:db8:3:6::6/64

interface g1.56
 encapsulation dot1q 56
 ip address 10.5.6.6 255.255.255.0
 ipv6 address 2001:db8:5:6::6/64




interface g1
 no shut

end

!!!ospf
!! r1
conf t
int g1.12
 ip ospf network point-to-point
 ip ospf 1 area 0
int g1.13
 ip ospf network point-to-point
 ip ospf 1 area 0
end

!! r2
conf t
int g1.12
 ip ospf network point-to-point
 ip ospf 1 area 0
end

!! r3
conf t
int g1.13
 ip ospf network point-to-point
 ip ospf 1 area 0
end

!! r1,r2,r3
conf t
int lo0
 ip ospf 1 area 0
end

!!!bgp connectivity
!! r1
conf t
router bgp 1
 neighbor 2.2.2.2 remote-as 1
 neighbor 2.2.2.2 update-source lo0
 neighbor 3.3.3.3 remote-as 1
 neighbor 3.3.3.3 update-source lo0
end

!! r2,r3
conf t
router bgp 1
 neighbor 1.1.1.1 remote-as 1
 neighbor 1.1.1.1 update-source lo0
end

!! r2
conf t
router bgp 1
 neighbor 10.2.4.4 remote-as 2
end

!! r3
conf t
router bgp 1
 neighbor 10.3.6.6 remote-as 4
end

!! r4
conf t
router bgp 2
 neighbor 10.2.4.2 remote-as 1
 neighbor 10.4.5.5 remote-as 3
end

!! r5
conf t
router bgp 3
 neighbor 10.4.5.4 remote-as 2
 neighbor 10.5.6.6 remote-as 4
end

!! r6
conf t
router bgp 4
 neighbor 10.3.6.3 remote-as 1
 neighbor 10.5.6.5 remote-as 3
end

!!!bgp networks
!! r1
conf t
router bgp 1
 network 1.1.1.1 mask 255.255.255.255
end

!! r5
conf t
router bgp 3
 network 172.16.50.0 mask 255.255.255.0
end
`
    }, {
        id: "networklessons.mpls.vpn",
        type: "sample",
        name: "networklessons.mpls.vpn",
        config: `! managed switch config
! paste into a managed switch to create vlans and setup dot1q

conf t
no ip domain-lookup
line console 0
 logging sync
 exec-timeout 0 0
line vty 0 4
 logging sync
 exec-timeout 0 0
int g0/0
sw tr en dot1q
sw mo tr
int g0/1
sw tr en dot1q
sw mo tr
int g0/2
sw tr en dot1q
sw mo tr
int g0/3
sw tr en dot1q
sw mo tr
int g1/0
sw tr en dot1q
sw mo tr
int g1/1
sw tr en dot1q
sw mo tr
int g1/2
sw tr en dot1q
sw mo tr
int g1/3
sw tr en dot1q
sw mo tr
int g2/0
sw tr en dot1q
sw mo tr
int g2/1
sw tr en dot1q
sw mo tr
int g2/2
sw tr en dot1q
sw mo tr
int g2/3
sw tr en dot1q
sw mo tr
int g3/0
sw tr en dot1q
sw mo tr
int g3/1
sw tr en dot1q
sw mo tr
int g3/2
sw tr en dot1q
sw mo tr
int g3/3
sw tr en dot1q
sw mo tr
vlan 1
vlan 12
vlan 2
vlan 23
vlan 3
vlan 34
vlan 4
vlan 45
vlan 5
exit

!!! topology

r1:r2
r2:r3

!!! addressing

!! r1
conf t
hostname r1

no ip domain-lookup
line console 0
 logging sync
 exec-timeout 0 0
line vty 0 4
 logging sync
 exec-timeout 0 0

interface lo0
 ip address 1.1.1.1 255.255.255.255
 ipv6 address 2001:db8::1/128

interface g1.1
 encapsulation dot1q 1
 ip address 172.16.10.1 255.255.255.0
 ipv6 address 2001:db8:10::1/64

interface g1.12
 encapsulation dot1q 12
 ip address 10.1.2.1 255.255.255.0
 ipv6 address 2001:db8:1:2::1/64



interface g1
 no shut

end




!! r2
conf t
hostname r2

no ip domain-lookup
line console 0
 logging sync
 exec-timeout 0 0
line vty 0 4
 logging sync
 exec-timeout 0 0

interface lo0
 ip address 2.2.2.2 255.255.255.255
 ipv6 address 2001:db8::2/128

interface g1.2
 encapsulation dot1q 2
 ip address 172.16.20.2 255.255.255.0
 ipv6 address 2001:db8:20::2/64

interface g1.12
 encapsulation dot1q 12
 ip address 10.1.2.2 255.255.255.0
 ipv6 address 2001:db8:1:2::2/64

interface g1.23
 encapsulation dot1q 23
 ip address 10.2.3.2 255.255.255.0
 ipv6 address 2001:db8:2:3::2/64



interface g1
 no shut

end




!! r3
conf t
hostname r3

no ip domain-lookup
line console 0
 logging sync
 exec-timeout 0 0
line vty 0 4
 logging sync
 exec-timeout 0 0

interface lo0
 ip address 3.3.3.3 255.255.255.255
 ipv6 address 2001:db8::3/128

interface g1.3
 encapsulation dot1q 3
 ip address 172.16.30.3 255.255.255.0
 ipv6 address 2001:db8:30::3/64

interface g1.23
 encapsulation dot1q 23
 ip address 10.2.3.3 255.255.255.0
 ipv6 address 2001:db8:2:3::3/64



interface g1
 no shut

end



!!! underlay (ospf)

!! r1
conf t
int lo0
 ip ospf 1 area 0
interface g1.12
 ip ospf 1 area 0
 ip ospf network point-to-point
end


!! r2
conf t
interface g1.12
 ip ospf 1 area 0
 ip ospf network point-to-point
interface g1.23
 ip ospf 1 area 0
 ip ospf network point-to-point
end


!! r3
conf t
int lo0
 ip ospf 1 area 0
interface g1.23
 ip ospf 1 area 0
 ip ospf network point-to-point
end

!!! mpls

!! r1,r2,r3
conf t
router ospf 1
    mpls ldp autoconfig area 0
end

!!! mpls verification

!! r2
show mpls ldp neighbor

!! r1,r2,r3
show mpls forwarding-table

!!! vrf

!! r1
conf t
ip vrf BLUE
 rd 100:1
 route-target export 100:1
 route-target import 300:1
int lo1
    ip vrf forwarding BLUE
    ip address 11.11.11.11 255.255.255.0
end

!! r3
conf t
ip vrf RED
 rd 300:1
 route-target export 300:1
 route-target import 100:1
int lo1
    ip vrf forwarding RED
    ip address 33.33.33.33 255.255.255.0
end

!!! bgp

!! r1
conf t
router bgp 13
    neighbor 3.3.3.3 remote-as 13
    neighbor 3.3.3.3 update-source lo0
    address-family vpnv4
        neighbor 3.3.3.3 activate
        neighbor 3.3.3.3 send-community extended
    address-family ipv4 unicast vrf BLUE
        redistribute connected
end

!! r3
conf t
router bgp 13
    neighbor 1.1.1.1 remote-as 13
    neighbor 1.1.1.1 update-source lo0
    address-family vpnv4
        neighbor 1.1.1.1 activate
        neighbor 1.1.1.1 send-community extended
    address-family ipv4 unicast vrf RED
        redistribute connected
end

!!! vpn verify

!! r1,r3

show bgp vpnv4 unicast all summary
show bgp vpnv4 unicast all

!!! reference

!! r1
conf t
interface g1.12
end


!! r2
conf t
interface g1.12
interface g1.23
end


!! r3
conf t
interface g1.23
interface g1.34
end
`
    }
]