export function parseConfig(config) {
    if (!config || config.length === 0) {
        return
    }
    let re = /([ \t]*)int(erface)? ([a-km-zA-K-Z]+)([0-9]+)\/?([0-9]*)([ \t]*)/ig
    let results = config.match(re)
    // console.log("first", results)
    let $interface
    if (results) {
        //+ first find
        $interface = [...new Set(results)][0].replace(/interface /, '')
        // config = config.replace(new RegExp($interface, "g"), '{interface}')
    }

    const lines = config.split("\n")
    let layers = []
    let buffer = []
    let name = ''
    let anchor
    let layer
    let n = 1
    let isImmutable = false
    for (let line of lines) {
        // if (line.includes("7f06af11")) {
        //     debugger
        // }
        // if (line.startsWith("{") && line.endsWith("}")) {
        //     // console.log("FOUND IMMUTABLE MARKERS")
        //     if (isImmutable) {
        //         // console.log("IS IMMUTABLE")
        //         re = /^\{\/[0-9a-f]{50}[0-9a-f]+\}$/ig
        //         results = line.match(re)
        //         if (results) {
        //             isImmutable = false
        //             // console.log("ENDING IMMUTABLE")
        //         }
        //         continue
        //     }
        //     re = /^\{[0-9a-f]{50}[0-9a-f]+\}$/ig
        //     results = line.match(re)
        //     if (results) {
        //         isImmutable = true
        //         // console.log("STARTING IMMUTABLE")
        //     }
        //     continue
        // }
        if (line.startsWith("!!!")) {
            if (buffer.length > 0) {
                layer = { name: name.length > 0 ? name : 'README', text: buffer.join("\n"), n: n++ }
                if (anchor) {
                    layer.anchor = anchor
                }
                layers.push(layer)
            }
            anchor = undefined
            buffer = []
            re = /\{[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}\}/ig
            results = line.match(re)
            if (results) {
                anchor = results[0]
                line = line.replace(results[0], "")
                // line = "int {interface}." + results[5]
            }
            name = line.substring(3).trim()
            continue
        }
        else {
            buffer.push(line)
        }
    }
    if (buffer.length > 0) {
        layer = { name: name.length > 0 ? name : 'README', text: buffer.join("\n"), n: n++ }
        if (anchor) {
            layer.anchor = anchor
        }
        layers.push(layer)
    }
    // console.log("parsed layers", layers)
    for (const layerObj of layers) {
        const layerName = layerObj.name
        if (layerName === "topology" || layerName === "README") {
            continue
        }
        let layer = layerObj.text
        //+ second find
        let lines = layer.split("\n")
        const newLines = []
        if ($interface) {
            for (let line of lines) {
                let re = /^int(erface)? ([a-km-zA-K-Z]+)([0-9]+)\/?([0-9]*)$/i
                let results = line.trim().match(re)
                if (results) {
                    // console.log("first", results)
                    line = "interface {interface}"
                }
                re = /int(erface)? ([a-zA-Z]+)([0-9]+)\/?([0-9]+)?\.([0-9]+)/i
                results = line.match(re)
                if (results) {
                    line = line.replace(results[0], "interface {interface}." + results[5])
                    // line = "int {interface}." + results[5]
                }
                newLines.push(line)
            }
            // layer = layer.replace(new RegExp($interface, "g"), '{interface}')
            lines = newLines
        }
        let deepLayers = {}
        let buffer = []
        let name = ''
        for (let line of lines) {
            if (line.startsWith("!!")) {
                const text = buffer.join("\n")
                if (name.length > 0 && buffer.length > 0) {
                    deepLayers[name] = { text }
                }
                else if (text.length > 0) {
                    deepLayers["README"] = { text }
                }
                buffer = []
                name = line.substring(2).trim()
                continue
            }
            else {
                buffer.push(line)
            }
        }
        if (name.length > 0 && buffer.length > 0) {
            deepLayers[name] = { anchor: layerObj.anchor, text: buffer.join("\n") }
        }
        else {
            const text = buffer.join("\n")
            if (text.length > 0) {
                deepLayers["README"] = { text }
            }
        }
        // if (layerName === "interfaces") {
        //     layerObj.text = buffer.join("\n")
        //     continue
        // }
        layerObj.innerLayers = deepLayers
    }
    return layers
}