import React from 'react'
import Select from 'react-select'

// function categorize($data) {
//     function create(code) {
//         if (!code || code.length === 0) {
//             return
//         }
//         const parts = code.split(".")
//         const first = parts.shift()
//         const sub = parts.join(".")
//         if (parts.length > 0) {
//             const results = { [first]: create(sub) }
//             return results
//         }
//         else {
//             return { [code]: [] }
//         }
//     }
//     function isObject(item) {
//         return (item && typeof item === 'object' && !Array.isArray(item) && item !== null)
//     }
//     function mergeDeep(target, source) {
//         if (isObject(target) && isObject(source)) {
//             for (const key in source) {
//                 if (isObject(source[key])) {
//                     if (!target[key]) Object.assign(target, { [key]: {} })
//                     mergeDeep(target[key], source[key])
//                 }
//                 else {
//                     Object.assign(target, { [key]: source[key] })
//                 }
//             }
//         }
//         return target;
//     }
//     const pad = (level) => {
//         let str = ''
//         for (let n = 0; n < level; n++) {
//             str += '  '
//         }
//         return str
//     }
//     function $getFromMap($map, results, level = 0, code = "") {
//         for (const segment in $map) {
//             const $code = code.length > 0 ? code + "." + segment : segment
//             // // console.log("segment", segment)
//             if (Array.isArray($map[segment]) === true) {
//                 results.push({ code: $code, title: pad(level) + $code, item: $data.find(p => p.name === $code) })
//             }
//             else {
//                 $getFromMap($map[segment], results, level + 1, $code)
//                 results.push({ code: $code, title: pad(level) + $code })
//             }
//         }
//     }
//     let $map = {}
//     $data = $data.sort((a, b) => (a["name"] > b["name"]) ? 1 : -1)
//     for (const item of $data) {
//         $map = mergeDeep($map, create(item.name))
//     }
//     const results = []
//     $getFromMap($map, results)
//     const $results = results.sort((a, b) => (a["code"] > b["code"]) ? 1 : -1)
//     return $results
// }

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}

const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
}

// const lockedStyles = {
//     backgroundColor: '#EBECF0',
//     borderRadius: '2em',
//     color: '#172B4D',
//     display: 'inline',
//     fontSize: 12,
//     fontWeight: 'normal',
//     lineHeight: '1',
//     minWidth: 1,
//     padding: '0.16666666666667em 0.5em',
//     textAlign: 'center',
// }

// const dot = (color = '#ccc') => ({
//     alignItems: 'center',
//     display: 'flex',

//     ':before': {
//         backgroundColor: color,
//         borderRadius: 10,
//         content: '" "',
//         display: 'block',
//         marginRight: 8,
//         height: 10,
//         width: 10,
//     },
// })
// const dot2 = (color = '#f00') => ({
//     alignItems: 'center',
//     display: 'flex',

//     ':before': {
//         backgroundColor: color,
//         borderRadius: 10,
//         content: '" "',
//         display: 'block',
//         marginRight: 8,
//         height: 10,
//         width: 10,
//     },
// })

const formatGroupLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
)

const getOptionLabel = data => {
    return (
        <div className="option">
            <span>
                {/* {data.isFolder ? <img src="/images/folder.png" /> : <img src="/images/layers.png" />} */}
                {data.label}<span> </span>
                {data.starred ? <img alt="Immutable" src="/images/star.png" /> : <></>}
                {data.question ? <img alt="Immutable" src="/images/help.png" /> : <></>}
                {data.lesson ? <img alt="Immutable" src="/images/transmit.png" /> : <></>}
            </span>
        </div>)
}


const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isLocked }) => {
        return {
            ...styles,
        };
    },
    container: styles => ({ ...styles, width: '300px' }),
    input: styles => ({ ...styles }),
    placeholder: styles => ({ ...styles }),
    singleValue: (styles, { data }) => ({ ...styles }),
}

export default function ConfigDropDownList({ id, isAuthenticated, onDDLChange, builtInConfigs, customConfigs }) {
    // // console.log("getTemplatesElements", "id", id)
    // const options = []
    // // console.log("builtInConfigs", builtInConfigs)
    // const $templateData = categorize(builtInConfigs)
    // // console.log("$templateData", $templateData)
    // for (const p of $templateData) {
    //   if (p.item) {
    //     options.push(<option key={p.item.id} value={p.item.id}>{p.title}</option>)
    //   }
    //   else {
    //     options.push(<option key={"=" + uuidv4()} value={"=" + uuidv4()}>{p.title}</option>)
    //   }
    // }
    // if (customTemplates) {
    //   const $customTemplates = categorize(customTemplates)
    //   options.push(<option key="a" value="a">------------------</option>)
    //   for (const p of $customTemplates) {
    //     if (p.item) {
    //       options.push(<option key={p.item.id} value={p.item.id}>{p.title}</option>)
    //     }
    //     else {
    //       options.push(<option key={"=" + uuidv4()} value={"=" + uuidv4()}>{p.title}</option>)
    //     }
    //   }
    // }
    // else if (isAuthenticated) {
    //   options.splice(0, 0, <option key="b" value="b">No saved topologies</option>)
    // }
    // options.splice(0, 0, <option key="0" value="0">Select a config</option>)
    // console.log("getTemplatesElements", "id", id)
    const builtInOptions = []
    // console.log("builtInConfigs", builtInConfigs)
    // const $templateData = categorize(builtInConfigs)
    const $templateData = builtInConfigs
    // console.log("$templateData", $templateData)
    let selected
    for (const p of $templateData) {
        const $option = { value: p.id, label: p.name.trim(), isFolder: false, isLocked: p.isLocked || false }
        if (id === p.id) {
            selected = $option
        }
        builtInOptions.push($option)
    }
    const customOptions = []
    if (customConfigs && Array.isArray(customConfigs)) {
        const $customTemplates = customConfigs.sort((a, b) => (a["name"] > b["name"]) ? 1 : -1)
        for (const p of $customTemplates) {
            const $option = { value: p.id, label: p.name.trim(), isFolder: false, isLocked: p.isLocked || false, question: p.question, lesson: p.lesson, starred: p.starred }
            // if (p.starred) {
            //     console.log("$option", $option)
            // }
            if (id === p.id) {
                selected = $option
            }
            customOptions.push($option)
        }
    }
    else if (isAuthenticated) {
        // customOptions.splice(0, 0, <option key="b" value="b">No saved configs</option>)
        customOptions.push({ value: "b", label: "No configs saved" })
    }
    const groupedOptions = [
        {
            label: 'Built-in',
            options: builtInOptions,
        },
        {
            label: 'Custom',
            options: customOptions,
        },
    ]
    const filterOption = (candidate, input, data) => {
        return candidate.data.__isNew__ || candidate.data.label.includes(input)
    }
    // console.log(groupedOptions)
    return (
        <Select
            filterOption={filterOption}
            options={groupedOptions}
            value={selected}
            onChange={onDDLChange}
            getOptionLabel={getOptionLabel}
            formatGroupLabel={formatGroupLabel}
            styles={colourStyles}>
        </Select>
    )
}
