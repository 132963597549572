import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom"
import Editor from "@monaco-editor/react"
import ReactMarkdown from 'react-markdown'

import Swal from 'sweetalert2'

import CytoscapeComponent from 'react-cytoscapejs'
import cytoscape from 'cytoscape'
import cola from 'cytoscape-cola'
// import svg from 'cytoscape-svg'

import $config from "./config.json"

// import 'purecss/build/pure.css'
// import 'purecss/build/grids-responsive.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup'
import Image from 'react-bootstrap/Image'
import Spinner from 'react-bootstrap/Spinner'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import './App.css'

import { parse as parseTopology, getNodeData } from './Topology'
import { builtInConfigs } from './Configs'
import { parseConfig } from './ParseConfig'
import { IosVersion } from './IosVersion'

import ConfigDropDownList from './ConfigDropDownList'

import { uuidv4 } from './Uuid'

// cytoscape.use(svg)
cytoscape.use(cola)

function Profile({ isLoading, user }) {
  if (isLoading || !user) {
    return <Spinner animation="grow" role="status" variant="primary">
      <span className="sr-only">Loading...</span>
    </Spinner >
  }

  return (
    <span className="profile">
      <Image src={user.picture} roundedCircle alt="Profile" />
      <span>{user.name}</span>
    </span>
  )
}

function LogoutButton({ isAuthenticated, logout }) {
  if (isAuthenticated) {
    return (
      <span className="control"><a className="link-nohover" onClick={() => logout({ returnTo: window.location.origin })}><span>Logout</span></a></span>
    )
  }
  return null
}

function LoginButton({ isAuthenticated, loginWithRedirect }) {
  if (!isAuthenticated) {
    return (
      <Button variant="info" onClick={() => loginWithRedirect()}>
        Login
      </Button>
    )
  }
  return null
}
function getSampleTutorialId() {
  return builtInConfigs.find(p => p.name == "sample.tutorial").id
}
export default function Main({ theme, setTheme, logout, loginWithRedirect, isLoading, topRef, user, isAuthenticated, getAccessTokenSilently }) {
  const iosVersionKey = "::iosVersion"

  const [graphFreeze, setGraphFreeze] = useState(false)
  const [svg, setSvg] = useState()
  const [png, setPng] = useState()
  const [config, setConfig] = useState()
  const [options, setOptions] = useState({
    selectOnLineNumbers: true
  })
  const { paramid } = useParams()
  const [id, setID] = useState(paramid || getSampleTutorialId())
  // const [exported, setExported] = useState("")

  //+ dirty means not saved to server
  const [dirty, setDirty] = useState(false)
  const [name, setName] = useState('')
  const [isLocked, setIsLocked] = useState(false)
  const [activeLayer, setActiveLayer] = useState()
  const [starred, setStarred] = useState()
  const [question, setQuestion] = useState()
  const [lesson, setLesson] = useState()
  const [extendsId, setExtendsId] = useState("")
  const [parentName, setParentName] = useState("")
  const [configCache2, setConfigCache2] = useState([])
  const [configCache, setConfigCache] = useState([])
  const [parsedData, setParsedData] = useState()
  const [parsedLayers, setParsedLayers] = useState()
  // const [layerSection, setLayerSection] = useState()
  const [customConfigs, setCustomConfigs] = useState()
  const [editorKeywords, setEditorKeywords] = useState()
  const [iosVersion, setIosVersion] = useState(localStorage.getItem(iosVersionKey) || 'g2')

  const [topology, setTopology] = useState()
  const [elements, setElements] = useState([])
  const [showEditor, setShowEditor] = useState(false)
  const [showGraph, setShowGraph] = useState(true)
  const [graphStyle, setGraphStyle] = useState({ height: '250px', width: '300px' })
  const [cy, setCy] = useState(0)
  const [layout, setLayout] = useState({ name: 'cola', animate: false })

  const refs = useRef({})
  let history = useHistory()

  // useEffect(() => {
  //   // console.log("check", "use effect", "[cy]")
  //   if (cy) {
  //     cy.on('tap', 'node', function (evt) {
  //       var node = evt.target
  //       // console.log('tapped node ' + node.id())
  //     })
  //     cy.on('tap', 'edge', function (evt) {
  //       var node = evt.target
  //       // console.log('tapped edge ' + node.id())
  //     })
  //     cy.on('tap', function (event) {
  //       var evtTarget = event.target
  //       if (evtTarget === cy) {
  //         // console.log('tap on background')
  //       }
  //     })
  //   }
  // }, [cy])

  async function copyToClipboard(contents) {
    navigator.clipboard.writeText(contents)
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    Toast.fire({
      icon: 'success',
      title: 'Config copied to clipboard.'
    })
  }

  // useEffect(() => {
  //   console.log("starred, question, lesson", starred, question, lesson)
  // }, [starred, question, lesson])

  useEffect(() => {
    localStorage.setItem(iosVersionKey, iosVersion)
  }, [iosVersion])

  useEffect(() => {
    (async () => {
      // console.log("use effect", "[]")
      if (isAuthenticated) {
        await loadConfigs()
        // await loadConfig()
      }
    })()
  }, [isAuthenticated])

  useEffect(() => {
    (async () => {
      // console.log("use effect", "[id]", id)
      await loadConfig()
      let item
      if (customConfigs) {
        item = customConfigs.find(p => p.id == id)
      }
      if (!item) {
        item = builtInConfigs.find(p => p.id == id)
      }
      history.push("/" + id)
      await loadEditorKeywords("ios")
    })()
  }, [id])

  useEffect(() => {
    // console.log("use effect", "[config, innerName]", "len(config)", config ? config.length : 'undefined')
    let $parseData = []
    let readme
    if (config && config.length > 0 && config !== "undefined") {
      const $_ = parseConfig(config)
      for (let obj of $_) {
        if (obj.name === "README" && !isImmutable()) {
          readme = obj
          continue
        }
        $parseData.push(obj)
      }
      //TODO: this NEEDS to be an array
      //TODO: prevent extended to have !!! TOPOLOGY; or just ignore it
    }
    if (extendsId && configCache2[extendsId]) {
      let $extends = extendsId
      let $array = []
      do {
        const config = configCache2[$extends]
        const $inner = []
        for (let ob in config.parsed) {
          // if (ob === "README") {
          //   continue
          // }
          $inner.push(config.parsed[ob])
        }
        $extends = config.extends
        $array = [...$inner, ...$array]
      } while ($extends)
      $parseData = [...$array, ...$parseData]
    }
    if (readme) {
      $parseData = [readme, ...$parseData]
    }
    setParsedData($parseData)
    // console.log("parsed data set", $parseData)
    // console.log("parsedData was not set")
  }, [config])

  const $refs = useRef({})
  function jumpToLayerContentDevice($index) {
    // console.log("jumpToLayerContentDevice", "$index", $index)
    if ($index === -1) {
      return
    }
    if ($refs.current[$index]) {
      $refs.current[$index].scrollIntoView({ behavior: "smooth", block: "start", inline: "center" })
    }
  }

  useEffect(() => {
    if (!parsedData) {
      return
    }
    // console.log("use effect", "[parsedData, iosVersion]")
    let n = 1
    let outer = []
    let firstSection
    let interfaces = []
    let _topology
    for (const layer of parsedData) {
      const name = layer.name
      // console.log("parsedData", 'name', name)
      // if (!firstSection) {
      //   firstSection = name
      // }
      if (name === "topology") {
        _topology = layer.text
        setTopology(layer.text)
        // const $index = name + n.toString()
        // outer.push({
        //   n: [layer.n],
        //   layerName: name,
        //   link:
        //     <OverlayTrigger trigger="hover" delay="0" placement="right" overlay={
        //       <Popover id="popover-basic">
        //         <Popover.Title as="h3">Topology</Popover.Title>
        //         <Popover.Content>
        //           <pre>{layer.text}</pre>
        //         </Popover.Content>
        //       </Popover>
        //     }>
        //       <ListGroup.Item key={$index} variant="primary">topology</ListGroup.Item>
        //     </OverlayTrigger>
        // })
        // outer[name] = { layerName: name, content: parsedData[name] }
        continue
      }
      else if (name === "README") {
        const $index = name + n.toString()
        outer.push({
          n: [layer.n],
          layerName: name,
          link: <ListGroup.Item key={uuidv4()} variant="info" onClick={() => jumpToLayerContentDevice($index)}>README</ListGroup.Item>,
          content: <div key={uuidv4()} ref={el => $refs.current[$index] = el}>
            <span className="layer">{name}</span>
            <pre><ReactMarkdown>{layer.text}</ReactMarkdown></pre>
          </div>
        })
        continue
      }
      // console.log(name)
      let inner = {}
      let m = 0
      for (const innerName in layer.innerLayers) {
        const _ = layer.innerLayers[innerName]
        const contents = _.text.replace(/{interface}/g, iosVersion)
        const id = name + innerName + n.toString() + m.toString()
        const $innerIndex = name + n.toString() + m.toString()
        const $nextIndex = (m + 1 < Object.keys(layer.innerLayers).length ? name + n.toString() + (m + 1).toString() : -1)
        if (name === "reference") {
          interfaces.push({ innerName, contents })
          continue
        }
        if (innerName === "README") {
          inner[innerName] = {
            // <div ref={el => $refs.current[n] = el} tabIndex="0" onKeyDown={ev => { [78].includes(ev.keyCode) ? jumpToLayerContentDevice(n + 1 < parts.length ? n + 1 : -1) : // console.log(ev.keyCode) }} >
            //   <h4>! {obj.innerName} <a className="link" onClick={() => jumpToLayerContentDevice(n + 1 < parts.length ? n + 1 : -1)}>(next)</a></h4>
            //   <span>{obj.content}</span>+
            // </div>

            // link: <li key={id} className="link" onClick={() => { setActiveLayer(name); setInnerName(innerName) }}>  {innerName}</li>,
            layerName: name,
            content:
              <div key={uuidv4()} tabIndex="0" onKeyDown={ev => [78].includes(ev.keyCode) ? jumpToLayerContentDevice($nextIndex) : console.log(ev.keyCode)}>
                <pre>{contents}</pre>
              </div>
          }
          continue
        }
        inner[innerName] = {
          link: <ListGroup.Item key={id} variant="info" className="device" onClick={() => jumpToLayerContentDevice($innerIndex)}>{innerName}</ListGroup.Item>,

          // <div ref={el => $refs.current[n] = el} tabIndex="0" onKeyDown={ev => { [78].includes(ev.keyCode) ? jumpToLayerContentDevice(n + 1 < parts.length ? n + 1 : -1) : // console.log(ev.keyCode) }} >
          //   <h4>! {obj.innerName} <a className="link" onClick={() => jumpToLayerContentDevice(n + 1 < parts.length ? n + 1 : -1)}>(next)</a></h4>
          //   <span>{obj.content}</span>+
          // </div>

          // link: <li key={id} className="link" onClick={() => { setActiveLayer(name); setInnerName(innerName) }}>  {innerName}</li>,
          layerName: name,
          content:
            <div key={$innerIndex} tabIndex="0" onKeyDown={ev => [78].includes(ev.keyCode) ? jumpToLayerContentDevice($nextIndex) : console.log(ev.keyCode)}>
              <span className="device" ref={el => $refs.current[$innerIndex] = el}>{innerName} <a className="link" onClick={() => jumpToLayerContentDevice($nextIndex)}>(next)</a></span>
              <pre className="link" onClick={() => copyToClipboard("!! " + innerName + "\n\n" + contents)}>{contents}</pre>
            </div>
        }
        m++
      }
      if (name === "reference") {
        // for(const item of interfaces){
        const contents = interfaces.map(p => "!! " + p.innerName + "\n" + p.contents + "\n").join("\n")
        // }
        const $index = name + n.toString()
        outer.push({
          n: [layer.n],
          layerName: name,
          link: <ListGroup.Item key={name} className="layer" onClick={() => jumpToLayerContentDevice($index)}>reference</ListGroup.Item>,
          content: <div key={name} ref={el => $refs.current[$index] = el}>
            <span className="layer">{name}</span>
            <pre className="link" onClick={() => copyToClipboard("!!! \n\n" + contents)}>{contents}</pre>
          </div>
        })
        continue
      }
      n++
      const $index = name + n.toString()
      // console.log("parsedData[name]", parsedData[name])
      const layerType = "layer"
      let link = <ListGroup.Item key={$index} className={layerType} onClick={() => jumpToLayerContentDevice($index)}>{name}</ListGroup.Item>
      outer.push({
        n: layer.n,
        inner,
        link: name === "addressing" && _topology
          ? <OverlayTrigger key={layer.n + $index.toString()} trigger={['hover', 'focus']} delay="0" placement="right" overlay={
            <Popover id="popover-basic">
              <Popover.Title as="h5">Topology</Popover.Title>
              <Popover.Content>
                <pre>{_topology}</pre>
              </Popover.Content>
            </Popover>
          }>
            {link}
          </OverlayTrigger>
          : link,
        content: <div key={$index} ref={el => $refs.current[$index] = el}>
          <span className="layer">{name}</span>
        </div>
        //  <a className="link" onClick={() => jumpToLayerContentDevice(name + (n + 1).toString())}>(next)</a>
      })
    }
    // if(outer["README"]) {
    //   debugger 
    // }
    setParsedLayers(outer)
    // setActiveLayer(firstSection)
  }, [parsedData, iosVersion])

  useEffect(() => {
    // console.log("check", "use effect", "[name]")
    if (name) {
      document.title = 'Lab Studio - ' + name
    }
  }, [name])

  // useEffect(() => {
  //   // console.log("use effect", "[parsedLayers, activeLayer, iosVersion]")
  //   if (!activeLayer) {
  //     return
  //   }
  //   const section = parsedLayers[activeLayer]
  //   if (!section) {
  //     // console.log("section is undefined")
  //     return
  //   }
  //   setLayerSection(section)
  // }, [parsedLayers, activeLayer])

  // useEffect(() => {
  //   // console.log("use effect", "[layerSection, activeLayer, innerName]")
  //   if (activeLayer) {
  //     const $id = activeLayer
  //     // // console.log("handleBackClick", "$id", $id, refs.current[$id])
  //     if (refs.current[$id]) {
  //       refs.current[$id].scrollIntoView({ behavior: 'smooth' })
  //     }
  //   }
  // }, [layerSection, activeLayer])

  function createPartitions(array, max) {
    return array.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / max)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])
  }

  function Layers({ }) {
    let names = []
    // console.log(parsedLayers)
    for (const _ in parsedLayers) {
      const layer = parsedLayers[_]
      const name = layer.name
      if (name === "topology") {
        continue
      }
      names.push(layer.link)
      // console.log(name)
      const devices = layer.inner
      let sublist = []
      for (const innerName in devices) {
        // console.log(name + "=>" + innerName)
        sublist.push(devices[innerName].link)
      }
      const $partitions = createPartitions(sublist, 5)
      let n = 0
      for (const $part of $partitions) {
        n++
        names.push(
          <ListGroup key={uuidv4()} horizontal>
            {$part}
          </ListGroup>)
      }
    }
    return (
      <ListGroup>
        {names}
      </ListGroup>
    )
  }

  function CytoscapeComponent2({ snapshotObj, setPng, elements, style }) {
    const [cy, setCy] = useState()
    const ref = useRef()

    useEffect(() => {
      // console.log("RENDERING")
      const config = {
        elements,
        layout: { name: 'cola', animate: false },
        container: ref.current,
        style: [{
          selector: 'node',
          css: {
            'label': 'data(id)',
            'text-valign': 'center',
            'text-halign': 'center',
            'height': '60px',
            'width': '60px',
          }
        }
        ]
      }
      setCy(cytoscape(config))
    }, [elements])

    function snapshot() {
      if (!cy) {
        return
      }
      setPng(cy.png())
      // console.log(cy.json())
    }
    snapshotObj.snapshot = snapshot

    return <div ref={ref} style={style} />
  }

  // function createPng() {
  //   setPng(cy.png())
  // }

  // function createSvg() {
  //   debugger
  //   const _svg = cy.svg()
  //   setSvg(_svg)
  //   console.log(_svg)

  //   // Swal.fire({
  //   //   title: '<strong>HTML <u>example</u></strong>',
  //   //   icon: 'info',
  //   //   html:
  //   //     'You can use <b>bold text</b>, ' +
  //   //     '<a href="//sweetalert2.github.io">links</a> ' +
  //   //     'and other HTML tags',
  //   //   showCloseButton: true,
  //   //   showCancelButton: true,
  //   //   focusConfirm: false,
  //   //   confirmButtonText:
  //   //     '<i class="fa fa-thumbs-up"></i> Great!',
  //   //   confirmButtonAriaLabel: 'Thumbs up, great!',
  //   //   cancelButtonText:
  //   //     '<i class="fa fa-thumbs-down"></i>',
  //   //   cancelButtonAriaLabel: 'Thumbs down'
  //   // })
  // }

  // let snapshotObj = {}
  // function freeze() {
  //   setGraphFreeze(true)
  //   if (snapshotObj.snapshot) {
  //     snapshotObj.snapshot()
  //   }
  // }
  // function unfreeze() {
  //   setGraphFreeze(false)
  // }

  // function hideGraph() {
  // }

  // function hideTopology() {
  // }

  function Graph() {
    // const popover = (
    //   <Popover id="popover-basic">
    //     <Popover.Title as="h3">Popover right</Popover.Title>
    //     <Popover.Content>
    //       <pre>{topology}</pre>
    //     </Popover.Content>
    //   </Popover>
    // )
    if (showGraph && elements.length > 0) {
      const styles = ""//showEditor || showSimpleEditor ? "" : "pure-u-1 pure-u-md-1-2"
      return <CytoscapeComponent elements={elements} layout={layout} style={graphStyle} />
    }
    else {
      return (
        <></>
      )
    }
  }

  useEffect(() => {
    const getColor = type => {
      switch (type) {
        case 'r': return '#1898cf'
        case 'xr': return '#44318d'
        case 'nx': return '#116466'
        case 's': return '#6a9955'
        case 'v': return '#ac3b61'
        default: //+ nothing
      }
    }
    if (!topology) {
      return
    }
    // console.log("topology", topology)
    const parsedData = parseTopology(topology)
    const graphData = getNodeData(parsedData)
    const { nodes, edges } = graphData
    // const groupId = uuidv4()
    // const group = {
    //   data: {id: groupId, label: "group" },
    //   style: {backgroundColor: getColor('#f00') }
    // }
    const nodeElements = nodes.map(p => {
      // console.log("p.group", p.group)
      return {
        data: { parent: p.group > 1000 ? String.fromCharCode(p.group - 1000 + 64) : p.group, id: p.id > 1000 ? String.fromCharCode(p.id - 1000 + 64) : p.id, label: p.name.startsWith("g") ? "" : p.name },
        style: { backgroundColor: getColor(p.type) }
      }
    })
    // nodeElements.push(group)
    const edgeElements = edges.map(p => {
      return {
        data: { source: parseInt(p.id), target: parseInt(p.remote), label: p.vlan },
        style: {
          'width': 3,
          'line-color': p.type === 'v' ? '#edb5bf' : '#ccc',
          'target-arrow-color': '#ccc',
          'target-arrow-shape': 'triangle'
        }
      }
    })
    setElements(nodeElements.concat(edgeElements))
    // setTimeout(() => {
    //   setShowGraph(true)
    // }, 10)
  }, [topology])

  function jumpToTheTop() {
    // console.log("jumpToTheTop")
    if (topRef.current.scrollIntoView) {
      topRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  function LayerContent() {
    // const $refs = useRef({ })
    // function jumpToLayerContentDevice(n) {
    //   if (n === -1) {
    //     return
    //   }
    //   // console.log("jumpToLayerContentDevice", "n", n)
    //   if ($refs.current[n]) {
    //     $refs.current[n].scrollIntoView({behavior: "smooth", block: "center", inline: "center" })
    //   }
    // }
    const parts = []
    // console.log("parsedLayers", parsedLayers)
    for (const layerName in parsedLayers) {
      const layerSection = parsedLayers[layerName]
      // console.log("layerName", layerName, "layerSection", layerSection)
      if (layerName === "topology") {
        continue
      }
      parts.push(layerSection.content)
      // parts.push({type: 'title', innerName: layerName })
      // for (const name in layerSection) {
      //   parts.push(layerSection[name])
      // }
      const $inner = layerSection.inner
      // console.log("layerName", layerName, "$inner ", $inner)
      for (const innerName in $inner) {
        const innerSection = $inner[innerName]
        // console.log("layerName", layerName, "innerSection.content", innerSection.content)
        parts.push(innerSection.content)
      }
    }

    // console.log("parts", parts)
    // const linked = []
    // for (let n = 0; n < parts.length; {
    //   const obj = parts[n]
    //   linked.push(obj.content)
    //   // switch (obj.type) {
    //   //   case 'title':
    //   //     linked.push(
    //   //       <div>
    //   //         <h4>{obj.innerName}</h4>
    //   //       </div>)
    //   //     break
    //   //   case 'literal':
    //   //     linked.push(
    //   //       <div>
    //   //         <h4>{obj.innerName}</h4>
    //   //         <pre>{obj.content}</pre>
    //   //       </div>
    //   //     )
    //   //     break
    //   //   default:
    //   //     linked.push(
    //   //       <div ref={el => $refs.current[n] = el} tabIndex="0" onKeyDown={ev => { [78].includes(ev.keyCode) ? jumpToLayerContentDevice(n + 1 < parts.length ? n + 1 : -1) : // console.log(ev.keyCode) }} >
    //   //         <h4>! {obj.innerName} <a className="link" onClick={() => jumpToLayerContentDevice(n + 1 < parts.length ? n + 1 : -1)}>(next)</a></h4>
    //   //         <span>{obj.content}</span>
    //   //       </div>)
    //   // }
    // }

    function TopLink() {
      if (parts.length) {
        return <a className="link" onClick={() => jumpToTheTop()}>Top</a>
      }
      return null
    }

    return (
      <>
        <div>
          {parts}
        </div>
        <TopLink />
      </>
    )
  }

  function isCustom(_id) {
    if (_id) {
      return _id.includes("-")
    }
    return id.includes("-")
  }
  function isBuiltIn(_id) {
    if (_id) {
      return _id.includes(".")
    }
    return id.includes(".")
  }
  function isImmutable(_id) {
    return !isCustom(_id) && !isBuiltIn(_id)
  }

  function getInputClassName() {
    return "input"
    // return "input" + (dirty ? " dirty" : "")
  }

  function handleSubmit(event) {
    event.preventDefault()
  }

  function createSummary($parsed) {
    let summary = ''
    for (const layer in $parsed) {
      if (layer === "topology") {
        summary = '!!! topology\n' + $parsed[layer]
        continue
      }
      summary += '\n\n!!! ' + $parsed[layer].name
      const $layer = $parsed[layer]
      if ($layer.innerLayers) {
        for (const $name in $layer.innerLayers) {
          summary += '\n!! ' + $name
        }
      }
    }
    return summary
  }

  function editConfig() {
    setShowEditor(true)
  }

  function duplicate() {
    // console.log("duplicate", id, name, config)
    const url = $config.api_base + '/api/v1/labs'
    getAccessTokenSilently()
      .then(token =>
        fetch(url + "/" + id, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          },
          body: JSON.stringify({ action: "duplicate" })
        })
          .then(async response => {
            const responseObject = await response.json()
            if (response.status > 204) {
              Swal.fire({
                icon: 'error',
                title: 'Cannot duplicate.',
                text: responseObject.message
              })
            } else {
              await loadConfigs()
              setID(responseObject.id)
              await loadConfig()
              const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              Toast.fire({
                icon: 'success',
                title: 'Duplicate created'
              })
            }
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: 'Cannot save.',
              text: err
            })
          })
      )
  }

  function importConfig() {
    Swal.fire({
      input: 'textarea',
      inputLabel: 'Lab',
      inputPlaceholder: `!!! topology
!!! addressing`,
      inputAttributes: {
        'aria-label': 'Enter your lab'
      },
      showCancelButton: true
    })
      .then(p => {
        if (p.isConfirmed === false) {
          // console.log("Import cancelled.")
          return
        }
        const text = p.value
        if (text.length === 0 || !text.includes("!!!")) {
          Swal.fire({
            icon: 'error',
            title: 'No layers found.',
            text: 'No layers found.'
          })
          return
        }
        setShowEditor(false)
        // console.log("importConfig")
        // setID(uuidv4())
        const url = $config.api_base + '/api/v1/labs'
        getAccessTokenSilently()
          .then(token =>
            fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
              },
              body: JSON.stringify({ config: text })
            })
              .then(async response => {
                const { id } = await response.json()
                if (response.status > 204) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Cannot create.',
                    text: 'Cannot create.'
                  })
                }
                else {
                  const $parsed = parseConfig(text)
                  const summary = createSummary($parsed)
                  if (summary) {
                    Swal.fire({
                      html: `<pre>! Imported\n\n${summary}</pre>`,
                      customClass: {
                        content: "alert-content"
                      }
                    })
                  }
                  await loadConfigs()
                  setID(id)
                }
              })
              .catch(err => {
                Swal.fire({
                  icon: 'error',
                  title: 'Cannot create.',
                  text: err
                })
              })
          )
      })
  }

  function hideEditor() {
    // console.log("hideExport")
    setShowEditor(false)
  }

  // function newConfig() {
  //   // console.log("newConfig")
  //   // setID(uuidv4())
  //   const url = $config.api_base + '/api/v1/labs'
  //   getAccessTokenSilently()
  //     .then(token =>
  //       fetch(url, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Authorization': 'Bearer ' + token
  //         }
  //       })
  //         .then(async response => {
  //           // // console.log(response)
  //           const { id } = await response.json()
  //           if (response.status > 204) {
  //             Swal.fire({
  //               icon: 'error',
  //               title: 'Cannot create.',
  //               text: 'Cannot create.'
  //             })
  //           }
  //           else {
  //             await loadConfigs()
  //             // // console.log("~newConfig", "id", id)
  //             setID(id)
  //           }
  //         })
  //         .catch(err => {
  //           Swal.fire({
  //             icon: 'error',
  //             title: 'Cannot create.',
  //             text: err
  //           })
  //         })
  //     )
  // }

  function lockConfig() {
    // console.log("deleteConfig", id, name)
    Swal.fire({
      html: `
      <p>Preserve will create an <strong>immutable</strong>, sharable copy of this lab (including the name). The ID will be public, but unlisted.</p>
      
      <p>Anyone with the ID can copy and build on your lab. It will not be linked to your account in any way, so don't lose the ID or leave in private details.</p>`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Preserve`
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          await saveConfig(false)
          const url = $config.api_base + '/api/v1/labs'
          getAccessTokenSilently()
            .then(token =>
              fetch(url + "/" + id, {
                method: 'PATCH',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({ action: "lock" })
              })
                .then(async response => {
                  // console.log(response)
                  const { id } = await response.json()
                  if (response.status > 204) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Cannot export.',
                      text: 'Cannot export.'
                    })
                  }
                  else {
                    if (id) {
                      Swal.fire({
                        html: `<pre>Immutable config created.\n\nClick ID to open:\n<a href="/${id}">${id}</a></pre>`,
                      })
                    }
                  }
                })
                .catch(err => {
                  Swal.fire({
                    icon: 'error',
                    title: 'Error creating immutable.',
                    text: err
                  })
                })
            )
        }
      })
  }

  function extendConfig() {
    Swal.fire({
      input: 'textarea',
      inputLabel: 'Enter a title',
      // inputPlaceholder: `a.b.c`,
      inputValue: name,
      inputAttributes: {
        'aria-label': 'Title',
        maxlength: 30,
        autocapitalize: 'off',
        autocorrect: 'off'
      },
      showCancelButton: true
    })
      .then(p => {
        if (p.isConfirmed === false) {
          // console.log("Import cancelled.")
          return
        }
        const name = p.value
        if (name.length === 0) {
          Swal.fire({
            icon: 'error',
            title: 'No title provided.',
            text: 'No title provided.'
          })
          return
        }
        setShowEditor(false)
        // console.log("importConfig")
        // setID(uuidv4())
        const url = $config.api_base + '/api/v1/labs'
        getAccessTokenSilently()
          .then(token =>
            fetch(url + "/" + id, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
              },
              body: JSON.stringify({ name })
            })
              .then(async response => {
                const { id } = await response.json()
                if (response.status > 204) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Cannot create.',
                    text: 'Cannot create.'
                  })
                }
                else {
                  await loadConfigs()
                  setID(id)
                }
              })
              .catch(err => {
                Swal.fire({
                  icon: 'error',
                  title: 'Cannot create.',
                  text: err
                })
              })
          )
      })
  }


  function saveConfig(showConfirmation = true) {
    // console.log("saveConfig", showConfirmation)
    const url = $config.api_base + '/api/v1/labs'
    getAccessTokenSilently()
      .then(token =>
        fetch(url + "/" + id, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          },
          body: JSON.stringify({ name, config, starred, question, lesson })
        })
          .then(async response => {
            const responseObject = await response.json()
            if (response.status > 204) {
              Swal.fire({
                icon: 'error',
                title: 'Cannot save.',
                text: responseObject.message
              })
            } else {
              setConfig(responseObject.config)
              await loadConfigs()
              setName(responseObject.name)
              setDirty(false)
              if (showConfirmation) {
                const Toast = Swal.mixin({
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
                })
                Toast.fire({
                  icon: 'success',
                  title: 'Config saved'
                })
              }
            }
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: 'Cannot save.',
              text: err
            })
          })
      )
  }

  function deleteConfig() {
    // console.log("deleteConfig", id, name)

    Swal.fire({
      text: "Are you sure you want to delete this?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Delete ${name}?`
    })
      .then((result) => {
        if (result.isConfirmed) {
          const url = $config.api_base + '/api/v1/labs'
          getAccessTokenSilently()
            .then(token =>
              fetch(url + "/" + id, {
                method: 'DELETE',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + token
                }
              })
                .then(async response => {
                  if (response.status !== 204) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Cannot delete.',
                      text: 'Cannot delete.'
                    })
                  } else {
                    const Toast = Swal.mixin({
                      toast: true,
                      position: 'top-end',
                      showConfirmButton: false,
                      timer: 3000,
                      timerProgressBar: true,
                      didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                      }
                    })
                    Toast.fire({
                      icon: 'success',
                      title: 'Deleted.'
                    })
                    // setConfig('')
                    // setName('')
                    await loadConfigs()
                    setID(getSampleTutorialId())
                  }
                })
                .catch(err => {
                  Swal.fire({
                    icon: 'error',
                    title: 'Cannot save.',
                    text: err
                  })
                })
            )
        }
      })
  }

  async function loadConfigs() {
    // console.log("loadConfigs")
    if (!isAuthenticated) {
      return
    }
    const url = $config.api_base + '/api/v1/labs'
    const token = await getAccessTokenSilently()
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      const topologies = await response.json()
      setCustomConfigs(topologies)
      // Swal.fire('Saved.')
    } catch (err) {
      console.error(err)
      Swal.fire({
        icon: 'error',
        title: 'Cannot load.',
        text: 'Error loading topologies.'
      })
    }
  }

  async function loadEditorKeywords(type) {
    try {
      const response = await fetch("/" + type + ".json", {
        method: 'GET',
        headers: {}
      })
      const keywords = await response.json()
      setEditorKeywords(keywords)
      return keywords
    }
    catch (err) {
      console.error(err)
      Swal.fire({
        icon: 'error',
        title: 'Cannot load.',
        text: 'Error loading editor settings.'
      })
    }
  }

  async function fetchTopology(_id, isABase = false) {
    // console.log("fetchTopology", id)

    let headers = {
      'Content-Type': 'application/json'
    }
    let url = $config.api_base + '/api/v1/labs'
    if (isImmutable(_id) === true) {
      url += "/immutable"
    }
    else {
      if (!isAuthenticated) {
        return
      }
      const token = await getAccessTokenSilently()
      headers['Authorization'] = 'Bearer ' + token
    }
    try {
      url += "/" + _id
      const response = await fetch(url, {
        method: 'GET',
        headers
      })
      const $config = await response.json()
      if (isABase) {
        //+ parse and cache when it's a base
        const parsed = parseConfig($config.config)
        const $layers = []
        for (const name in parsed) {
          $layers.push(parsed[name])
        }
        let obj = { parsed: [...$layers] }
        if ($config.extends) {
          obj.extends = $config.extends
        }
        configCache2[_id] = obj
        setConfigCache2(configCache2)
      }
      if ($config.extends && $config.extends.length > 0) {
        //+ don't return, just cache
        await fetchTopology($config.extends, true)
      }
      return $config
    }
    catch (err) {
      console.error(err)
      Swal.fire({
        icon: 'error',
        title: 'Cannot load.',
        text: 'Error loading topologies.'
      })
    }
  }

  // async function fetchPng(id) {
  //   // console.log("fetchPng", id)

  //   let headers = {
  //   }
  //   try {
  //     const response = await fetch('https://configlayers.netfxharmonics.com/labimages/' + id + '.png.base64', { method: 'GET' })
  //     const $config = await response.text()
  //     return $config
  //   }
  //   catch (err) {
  //     console.error(err)
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Cannot load.',
  //       text: 'Error loading topologies.'
  //     })
  //   }
  // }

  function onDDLChange(event) {
    setShowEditor(false)
    const value = event.value
    const go = () => {
      setDirty(false)
      setID(value)
    }
    if (value === "a" || value === "b" || value === "0" || value.startsWith("=")) {
      return
    }
    if (!isCustom() || !dirty) {
      go()
      return
    }
    Swal.fire({
      text: "Load another config, without saving?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, continue loading.'
    }).then((result) => {
      if (result.isConfirmed) {
        go()
        return
      }
    })
  }

  const toggleTheme = () => {
    if (theme === 'light') {
      setTheme('dark')
    } else {
      setTheme('light')
    }
  }

  async function loadConfig() {
    // console.log("loadConfig", "id", id)
    if (!id || id === "-1" || id === "0" || id === "1" || id.startsWith("=")) {
      return
    }
    if (isCustom() === true && isAuthenticated === false) {
      Swal.fire({
        icon: 'error',
        title: 'Not logged in..',
        text: 'Login and try again.'
      })
      return
    }
    // if (true || isImmutable() === true || (isCustom() === true && isAuthenticated)) {
    // console.log("custom")
    let item = await fetchTopology(id)
    // if(item.hasPng){
    //   await fetchPng(id)
    // }
    // for(const baseId in bases) {
    //   const base = bases[baseId]
    //   //+ ensure parsed exists for each
    //   const config = base.obj.config
    //   if (!base.parsed && config && config.length > 0) {
    //     base.parsed = parseConfig(config)
    //   }
    // }
    // }
    // else {
    //   // console.log("not custom")
    //   item = builtInConfigs.find(p => p.id === id)
    // }
    // console.log("loadConfig", "item", item)
    if (item) {
      setExtendsId(item.extends || "")
      setParentName(item.parent || "")
      // let $extends
      // let config = ''
      // let item2 = {config: item.config, extends: item.extends }
      // while (item2 = configCache2[item2.extends]) {
      //   config += item2.config
      // }
      // debugger
      // config += item.config
      setConfig(item.config || '')
      setName(item.name)
      setStarred(item.starred || false)
      setQuestion(item.question || false)
      setLesson(item.lesson || false)
      setIsLocked(item.locked || false)
      setDirty(false)
    }
    else {
      // // console.log("loadConfig", "NOT FOUND", id)
      Swal.fire({
        icon: 'error',
        title: 'Not found.',
        text: 'Topology not found.'
      })
    }
  }

  let timer
  function updateConfig(value) {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(function () {
      // console.log("updateConfig", id)
      const item = builtInConfigs.find(p => p.id === id)
      // // console.log("onConfigChange", "item", item)
      if (item) {
        setName('')
      }
      setDirty(true)
      setConfig(value)
    }, 500)
  }

  async function handleEditorWillMount(monaco) {
    monaco.languages.register({ id: 'ios' })
    monaco.languages.setMonarchTokensProvider('ios', {
      defaultToken: '',
      tokenPostfix: '.ios',

      keywords: editorKeywords,

      brackets: [
      ],

      tokenizer: {
        root: [
          { include: '@whitespace' },
          { include: '@numbers' },

          [/[,:;]/, 'delimiter'],
          [/[{}\[\]()]/, '@brackets'],

          [/@[a-zA-Z]\w*/, 'tag'],
          [/[a-zA-Z][\w\-]*/, {
            cases: {
              '@keywords': 'keyword',
              '@default': 'identifier'
            }
          }]
        ],

        // Deal with white space, including single and multi-line comments
        whitespace: [
          [/\s+/, 'white'],
          [/(^!.*$)/, 'comment']
        ],

        // Recognize hex, negatives, decimals, imaginaries, longs, and scientific notation
        numbers: [
          [/-?0x([abcdef]|[ABCDEF]|\d)+[lL]?/, 'number.hex'],
          [/-?(\d*\.)?\d+([eE][+\-]?\d+)?[jJ]?[lL]?/, 'number'],
          [/\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/, 'number'],
          [/(([0-9a-fA-F]{1, 4}:){7, 7}[0-9a-fA-F]{1, 4}|([0-9a-fA-F]{1, 4}:){1, 7}:|([0-9a-fA-F]{1, 4}:){1, 6}:[0-9a-fA-F]{1, 4}|([0-9a-fA-F]{1, 4}:){1, 5}(:[0-9a-fA-F]{1, 4}){1, 2}|([0-9a-fA-F]{1, 4}:){1, 4}(:[0-9a-fA-F]{1, 4}){1, 3}|([0-9a-fA-F]{1, 4}:){1, 3}(:[0-9a-fA-F]{1, 4}){1, 4}|([0-9a-fA-F]{1, 4}:){1, 2}(:[0-9a-fA-F]{1, 4}){1, 5}|[0-9a-fA-F]{1, 4}:((:[0-9a-fA-F]{1, 4}){1, 6})|:((:[0-9a-fA-F]{1, 4}){1, 7}|:)|fe80:(:[0-9a-fA-F]{0, 4}){0, 4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1, 4}){0, 1}:){0, 1}((25[0-5]|(2[0-4]|1{0, 1}[0-9]){0, 1}[0-9])\.){3, 3}(25[0-5]|(2[0-4]|1{0, 1}[0-9]){0, 1}[0-9])|([0-9a-fA-F]{1, 4}:){1, 4}:((25[0-5]|(2[0-4]|1{0, 1}[0-9]){0, 1}[0-9])\.){3, 3}(25[0-5]|(2[0-4]|1{0, 1}[0-9]){0, 1}[0-9]))/, 'number']
        ]
      }
    })
  }

  function handleEditorDidMount(editor, monaco) {
    monacoRef.current = editor
  }

  const monacoRef = useRef(null)
  return (
    <Form onKeyDown={ev => {
      const charCode = String.fromCharCode(ev.which).toLowerCase()
      if (showEditor && !isLocked && ev.ctrlKey && charCode === 's') {
        saveConfig()
        ev.preventDefault()
      }
    }}>
      <span ref={topRef}></span>
      <Container fluid>
        <Row>
          <Col xs={6} md={4}>
            <div className="topPane">
              <header>
                <Container>
                  <Row>
                    <Col>
                      <span className="heading"><a className="link-nohover" href="/">Lab Studio</a></span>
                    </Col>
                    <Col>
                      <div className="global-settings">
                        {/* {!isAuthenticated && (<LoginButton loginWithRedirect={loginWithRedirect} />)} */}
                        {/* <span><Button variant="info" onClick={toggleTheme}>Theme</Button></span> */}
                        {isAuthenticated && <Profile isLoading={isLoading} user={user} />}
                        <LogoutButton isAuthenticated={isAuthenticated} logout={logout} />
                        <LoginButton isAuthenticated={isAuthenticated} loginWithRedirect={loginWithRedirect} />
                      </div>
                      <div className="col-md-6">
                      </div>
                    </Col>
                  </Row>
                </Container>
              </header>
              <div className="heading">
                {/* <p>Created by <a target="_blank" rel="noopener noreferrer" href="https://davidbetz.net">David Betz</a> | Related: <a target="_blank" rel="noopener noreferrer" href="https://logicaltopology.netfxharmonics.com/">Logical Topology Generator</a></p> */}
                {/* <p>You do labs in layers (not task order). Track configs in layers.</p> */}
              </div>
              {isAuthenticated &&
                <>
                  <span className="control"><a className="link" target="_blank" href="https://logicaltopology.netfxharmonics.com"><img alt="add" src="/images/picture_empty.png" /><span>Design</span></a></span>
                  <span className="control"><a className="link" onClick={() => importConfig()}><img alt="add" src="/images/add.png" /><span>Import</span></a></span>
                  {!isImmutable()
                    ? <span className="control"><a className="link" onClick={() => duplicate()}><img alt="duplicate" src="/images/page_copy.png" /><span>Duplicate</span></a></span>
                    : <></>}
                </>
              }
              <div className="control">
                <ConfigDropDownList id={id} isAuthenticated={isAuthenticated} onDDLChange={onDDLChange} builtInConfigs={builtInConfigs} customConfigs={customConfigs} />
              </div>
              <div className="based-on">
                {extendsId.length > 0
                  ? <p>Based on <a href={extendsId}>{parentName.length > 0 ? parentName : extendsId}</a></p>
                  : <></>}
              </div>
              {isAuthenticated && <div>
                <Form.Control type="text" disabled={isCustom() ? "" : "disabled"} onChange={e => { setDirty(true); setName(e.target.value) }} value={name} />
              </div>}
              {/* <input id="name" name="name" className="input" disabled={isCustom() ? "" : "disabled"} value={name} onChange={e => { setDirty(true); setName(e.target.value) }} /></div> */}
              <div className="control-bar">
                {isAuthenticated &&
                  <>
                    {/* <p>[len(elements):{elements.length}]</p>
                <p>[id:{id}]</p>
                <p>[isImmutable:{isImmutable().toString()}]</p>
                <p>[isLocked:{isLocked.toString()}]</p>
                <p>[dirty:{dirty.toString()}]</p>
                <p>[len(config):{(config || '').length}]</p>
                <p>[name:{name}]</p> */}
                    {/* <button className="pure-button" onClick={() => newConfig()} >New</button> */}
                    {/* {graphFreeze
                      ?
                      <OverlayTrigger delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="tooltip-disabled">Resume topology rendering.</Tooltip>}>
                        <span className="d-inline-block">
                          <a className="link" onClick={() => unfreeze()}><img src="/images/control_play.png" />Render</a>
                        </span>
                      </OverlayTrigger>
                      :
                      <OverlayTrigger delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="tooltip-disabled">Pause topology rendering.</Tooltip>}>
                        <span className="d-inline-block">
                          <a className="link" onClick={() => freeze()}><img src="/images/control_pause.png" />Pause</a>
                        </span>
                      </OverlayTrigger>
                    } */}
                    {/* <OverlayTrigger delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="tooltip-disabled">Get PNG of rendering.</Tooltip>}>
                      <span className="d-inline-block">
                        <a className="link" onClick={() => createPng()}><img src="/images/image.png" /></a>
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="tooltip-disabled">Get SVG of rendering.</Tooltip>}>
                      <span className="d-inline-block">
                        <a className="link" onClick={() => createSvg()}><img src="/images/vector.png" /></a>
                      </span>
                    </OverlayTrigger> */}
                    <OverlayTrigger trigger="click" placement="right" overlay={<Popover id="popover-basic">
                      <Popover.Title as="h5">Settings</Popover.Title>
                      <Popover.Content>
                        <IosVersion iosVersion={iosVersion} setIosVersion={setIosVersion} />
                      </Popover.Content>
                    </Popover>}>
                      <span className="control"><a className="link"><img alt="interface" src="/images/cog.png" /><span>Interface</span></a></span>
                    </OverlayTrigger>
                    <OverlayTrigger trigger="click" placement="right" overlay={<Popover id="popover-basic">
                      <Popover.Title as="h5">Tags</Popover.Title>
                      <Popover.Content>
                        <Form.Check
                          type="checkbox"
                          label="starred"
                          id="markStarred"
                          checked={starred}
                          onChange={(e) => setStarred(e.target.checked)}
                        />
                        <Form.Check
                          type="checkbox"
                          label="question"
                          id="markQuestion"
                          checked={question}
                          onChange={(e) => setQuestion(e.target.checked)}
                        />
                        <Form.Check
                          type="checkbox"
                          label="lesson"
                          id="markLesson"
                          checked={lesson}
                          onChange={(e) => setLesson(e.target.checked)}
                        />
                      </Popover.Content>
                    </Popover>}>
                      <span className="control"><a className="link"><img alt="interface" src="/images/cog.png" /><span>Tags</span></a></span>
                    </OverlayTrigger>
                    {isCustom() ?
                      <>
                        {!showEditor && !isLocked && <span className="control"><a className="link" onClick={() => editConfig()}><img alt="edit" src="/images/pencil.png" /><span>Edit</span></a></span>}
                        {showEditor && <a className="link" onClick={() => hideEditor()}><img alt="hide" src="/images/contrast.png" /><span>Hide</span></a>}
                        {!isLocked && <span className="control"><a className="link" onClick={() => saveConfig(true)}><img alt="save" src="/images/disk.png" /><span>Save</span></a></span>}
                        <span className="control"><a className="link" onClick={() => deleteConfig()}><img alt="delete" src="/images/delete.png" /><span>Delete</span></a></span>
                        {!isLocked && <OverlayTrigger delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="tooltip-disabled">Lock and share.</Tooltip>}>
                          <span className="d-inline-block">
                            <span className="control"><a className="link" onClick={() => lockConfig()}><img alt="preserve" src="/images/world_go.png" /><span>Preserve</span></a></span>
                          </span>
                        </OverlayTrigger>}
                      </>
                      : <>
                        <span className="control"><a className="link" onClick={() => editConfig()}><img alt="view raw" src="/images/magnifier.png" /><span>View Raw</span></a></span>
                      </>}
                    {isImmutable()
                      ? <OverlayTrigger delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="tooltip-disabled">Create your own based on this immutable config.</Tooltip>}>
                        <span className="d-inline-block">
                          <span className="control"><a className="link" onClick={() => extendConfig()}><img alt="extend" src="/images/layers.png" /><span>Extend</span></a></span>
                        </span>
                      </OverlayTrigger>
                      : <></>}
                  </>
                }

              </div>
              <div className="bottomPane">
                <Graph />
                <Layers />
              </div>
            </div>
          </Col>
          <Col>
            <div className="content">
              {showEditor &&
                <Editor width="100%" height="80vh" defaultLanguage="ios" options={options} defaultValue={config} beforeMount={handleEditorWillMount} onChange={() => { updateConfig(monacoRef.current.getValue()) }} onMount={handleEditorDidMount} />
              }
              {/* {cy ? <img src={"data:image/png;base64," + cy.png()} /> : <></>} */}
              <LayerContent />
            </div>
          </Col>
        </Row>
      </Container>
      {/* <div className="pure-g">
          <div className="pure-u-1 pure-u-md-1-3">
          </div>
          <div className="pure-g">
            <div className="pure-u-1-1">
            </div>
          </div>
        </div>
        <div className="pure-u-1 pure-u-md-2-3">

        </div> */}
    </Form >
  )

}