function getDeviceId(deviceName) {
    const re = /^(g|xr|r|nx|s|v)([0-9]+)$/g
    const captures = re.exec(deviceName)
    if (captures === null || captures.length < 3) {
        return
    }
    const deviceType = captures[1]
    let deviceId = parseInt(captures[2])
    if (deviceType === 'xr') {
        deviceId += 10
    }
    if (deviceType === 's') {
        deviceId += 30
    }
    if (deviceType === 'nx') {
        deviceId += 60
    }
    if (deviceType === 'g') {
        deviceId += 1000
    }
    return { deviceType, deviceId }
}

function parse(config) {
    if (!config || config.length === 0) {
        return
    }
    const devices = {}
    const lines = config.split("\n").map(p => p.trim()).filter(p => p.length > 0)
    for (const line of lines) {
        const parts = line.split(':')
        const deviceName = parts[0]
        if (deviceName[0] === 'v') {
            //+ vlans can't connect to anyone
            continue
        }
        const deviceParts = getDeviceId(deviceName)
        if (!deviceParts) {
            continue
        }
        const { deviceType, deviceId } = deviceParts
        let device = devices[deviceId]
        if (typeof device === 'undefined') {
            device = {
                name: deviceName,
                type: deviceType,
                id: deviceId,
                links: []
            }
            devices[deviceId] = device
        }
        if (parts.length < 2) {
            continue
        }
        if (!device) {
            continue
        }
        const links = parts[1].split(",").map(p => p.trim()).filter(p => p.length > 0)
        for (let linkName of links) {
            const linkData = getDeviceId(linkName)
            if (!linkData) {
                continue
            }
            if (deviceType === "g") {
                // console.log("linkName", linkName)
            }
            const linkType = linkData.deviceType
            const linkId = linkData.deviceId
            if (!device.links.find(p => p.id === linkId)) {
                if (deviceType === "g") {
                    // console.log("linkType", linkType)
                }
                let vlan
                if (linkType === 'v') {
                    vlan = linkId
                }
                else {
                    vlan = createVlan(device.id, linkId)
                }
                let group
                if (linkType === 'g') {
                    group = deviceId
                }
                device.links.push({ group, name: linkName, vlan, type: linkData.deviceType === 'v' ? 'v' : linkData.deviceType, remote: device.id, id: linkId })
            }
        }
    }
    for (let deviceName of Object.keys(devices)) {
        const device = devices[deviceName]
        // console.log("device", device)
        if (device.name[0] === 'v') {
            continue
        }
        for (let link of device.links) {
            let remoteDevice = devices[link.id]
            if (typeof remoteDevice === 'undefined') {
                remoteDevice = {
                    name: link.name,
                    type: link.type,
                    id: link.id,
                    links: []
                }
                devices[link.id] = remoteDevice
            }
            if (device.type === "g") {
                remoteDevice.group = device.id
            }
            if (typeof remoteDevice.links.find(p => p.id === device.id) === 'undefined') {
                const vlan = createVlan(device.id, link.id)
                remoteDevice.links.push({ name: device.name, vlan, type: device.type === 'v' || remoteDevice.type === 'v' ? 'v' : remoteDevice.type, remote: link.id, id: device.id })
            }
        }
        if (device.type === "g") {
            device.links = []
        }
    }
    return devices
}

function createVlan(a, b) {
    if (a > b) {
        [a, b] = [b, a]
    }
    return a.toString() + b.toString()
}


function countDevices(devices) {
    if (!devices) {
        return 0
    }
    const deviceArray = Object.keys(devices).map(p => devices[p]).filter(p => p.type !== 'v')
    return Object.keys(deviceArray).length
}

// function countLinks(config) {
//     const devices = parse(config)
//     return countLinks(devices)
// }

function countLinks(devices) {
    if (!devices) {
        return 0
    }
    let linkCount = 0
    for (let id in devices) {
        const device = devices[id]
        linkCount += device.links.length
    }
    return linkCount / 2
}

// function getNodeData(config) {
//     const devices = parse(config)
//     getNodeData(devices)
// }

function getNodeData(parsedData) {
    const deviceArray = Object.keys(parsedData).map(p => parsedData[p])
    const nodes = deviceArray.map(p => { return { group: p.group, id: p.id, name: p.name, type: p.type } })
    const links = deviceArray.map(p => p.links).reduce((x, y) => x.concat(y), [])

    const unique = (array, property) => property
        ? array.map(e => e[property])
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter(e => array[e])
            .map(e => array[e])
        : [...new Set(array)]

    const edges = unique(links.filter(p => p.id < 1000), 'vlan')
    return { nodes, edges }
}

export { parse, getNodeData, countDevices, countLinks }
