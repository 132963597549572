export const lightTheme = {
    body: '#fff',
    hoverbody: '#E2E2E2',
    text: '#363537',
    title: '#363537',
    toggleBorder: '#FFF',
    gradient: 'linear-gradient(#39598A, #79D7ED)',
}

export const darkTheme = {
    body: '#363537',
    hoverbody: '#6a686d',
    text: '#FAFAFA',
    title: '#FAFAFA',
    toggleBorder: '#6B8096',
    gradient: 'linear-gradient(#091236, #1E215D)',
}
