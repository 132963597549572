//+ interfaces need stub
//+ consider adding ip addresses to interfaces as a comment

import React, { useRef, useState, useEffect } from "react"

import { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme } from './Theme'
import { GlobalStyles } from './Global'

import './App.css'

import { useAuth0 } from "@auth0/auth0-react"
import Main from "./Main"

import Spinner from 'react-bootstrap/Spinner'

export default function App() {
  let top = useRef()

  const { user, isAuthenticated, isLoading, logout, loginWithRedirect, getAccessTokenSilently } = useAuth0()

  const themeKey = "::theme"
  const [theme, setTheme] = useState(localStorage.getItem(themeKey) || 'light')

  useEffect(() => {
    localStorage.setItem(themeKey, theme)
  }, [theme])

  if (isLoading) {
    return <Spinner animation="grow" role="status" variant="primary">
      <span className="sr-only">Loading...</span>
    </Spinner >
  }
  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <>
        <GlobalStyles />
        <div ref={top}>
          <Main theme={theme} setTheme={setTheme} logout={logout} loginWithRedirect={loginWithRedirect} isLoading={isLoading} topRef={top} user={user} isAuthenticated={isAuthenticated} getAccessTokenSilently={getAccessTokenSilently} />
        </div>
      </>
    </ThemeProvider >
  )
}
